import { ClientError } from "graphql-request";
import { useCallback, useState } from "react";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { LexxApplication } from "../consts/LexxApplication";
import { userRoleMappingsService } from "../services/UserRoleMappingsService";
import { usersService } from "../services/UsersService";

/**
 * Handle user related requests
 */
export const useUsers = () => {
  const { data, mutate, error } = useSWRImmutable("Users", () => usersService.searchUsers("").then((result) => result));
  const [searching, setSearching] = useState(false);

  // Search users
  const searchUsers = (q) => {
    setSearching(true);
    mutate(async () => {
      return await usersService.searchUsers(q);
    }, false)
      .then(() => {
        setSearching(false);
      })
      .catch();
  };

  // Create an user
  const createUser = async ({
    username,
    firstName,
    lastName,
    email,
    password,
    availableApps,
    clientIDs,
    clientRolesID,
    clientRolesName
  }) => {
    try {
      await mutate(async (users) => {
        const user = await usersService.createUser({
          username,
          firstName,
          lastName,
          email,
          password,
          realmRoles: availableApps,
          clientIDs,
          clientRolesID,
          clientRolesName
        });
        user.availableApps = availableApps;
        return [...users, user];
      }, false);
    } catch (e) {
      throw e;
    }
  };

  // Delete an user
  const deleteUser = async (id) => {
    await mutate(async (data) => {
      await usersService.deleteUser(id);
      return data.filter((record) => record.id !== id);
    }, false);
  };

  return { data, error, loading: !data && !error, searching, searchUsers, createUser, deleteUser };
};

/**
 * Handle get user information by user ID
 */
export const useUser = (id) => {
  const { data, error } = useSWR(`User${id}`, () => usersService.getUser(id).then((result) => result));
  return { data, error, loading: !data && !error };
};

/**
 * Handle user client roles related requests
 */
export const useUserAppsWithClientRoles = (id) => {
  // Get user client roles
  const { data, mutate, error } = useSWRImmutable(`UserWithClientRoleMappings${id}`, () =>
    usersService.getUserClientRoleMappings(id).then((data) => {
      const userRoleMappingsByApps = data.reduce((acc, obj) => {
        const {
          roleInfo: {
            clientInfo: { clientId }
          }
        } = obj;
        if (!acc[clientId]) {
          acc[clientId] = [];
        }
        acc[clientId].push(obj);
        return acc;
      }, {});
      let appList = Object.keys(userRoleMappingsByApps).map((key) => {
        return { name: key, roles: userRoleMappingsByApps[key] };
      });
      // When the user do not have any role, append an empty XTeam and XAdmin role
      if (appList.length === 0) {
        // cheat (we need to think of how to refactor this in the future)
        appList = [
          { name: LexxApplication.VOUSER, roles: [] },
          { name: LexxApplication.VOADMIN, roles: [] }
        ];
      }
      // When the user has roles from one of the clients, append an empty role for the other client
      else if (appList.length === 1) {
        // cheat (we need to think of how to refactor this in the future)
        if (appList[0]["name"] == LexxApplication.VOADMIN) {
          appList.push({ name: LexxApplication.VOUSER, roles: [] });
        } else {
          appList.push({ name: LexxApplication.VOADMIN, roles: [] });
        }
      }
      return appList;
    })
  );

  // Add client roles to user
  const bindRole = async (userId, clientId, appName, newRole) => {
    await mutate(async (data) => {
      await userRoleMappingsService.addClientLevelRoleMapping(userId, clientId, newRole);
      return data.map((item) => {
        if (item.name === appName) {
          const { id: roleId, name: roleName } = newRole;
          const foundRole = item.roles.find((role) => role.roleInfo.name === roleName);
          return {
            ...item,
            roles: foundRole
              ? item.roles
              : [
                  {
                    roleId: roleId,
                    roleInfo: {
                      client: appName,
                      clientInfo: {
                        clientId: appName
                      },
                      name: roleName
                    }
                  },
                  ...item.roles
                ]
          };
        }

        return item;
      });
    }, false);
  };

  // Remove client roles from user
  const removeRole = async (userId, appName, clientId, deletedRole) => {
    await mutate(async (data) => {
      await userRoleMappingsService.deleteClientLevelRoleMapping(userId, clientId, deletedRole);
      return data.map((item) => {
        if (item.name === appName) {
          return {
            ...item,
            roles: item.roles.filter((role) => role.roleInfo.name !== deletedRole.name)
          };
        }

        return item;
      });
    }, false);
  };

  return { data, error, loading: !data && !error, bindRole, removeRole };
};

/**
 * Handle update user information
 */
export const useHandleUpdateUser = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState([]);

  const handleUpdateUser = useCallback(({ id, firstName, lastName }, onSuccess, onError) => {
    usersService
      .updateUser({ id, firstName, lastName })
      .then(() => {
        setSuccess(true);
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        setError(error);
        if (error instanceof ClientError) {
          if (onError) {
            const errorMessages = [];
            error.response.errors.forEach((error) => {
              errorMessages.push(error.message);
            });
            onError(errorMessages);
          }
        }
      });
  }, []);

  return [handleUpdateUser, { success, error, loading: !success && !error }];
};

/**
 * Handle update user profile photo
 */
export const useHandleUpdateAvatar = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState([]);

  const handleUpdateAvatar = useCallback((file, userid, onSuccess, onError) => {
    usersService
      .updateAvatar({ file, userid })
      .then((profileUrl) => {
        setSuccess(true);
        if (onSuccess) {
          onSuccess(profileUrl);
        }
      })
      .catch((error) => {
        setError(error);
        if (error instanceof ClientError) {
          if (onError) {
            const errorMessages = [];
            error?.response?.errors?.forEach((error) => {
              errorMessages.push(error.message);
            });
            onError(errorMessages);
          }
        }
      });
  }, []);

  const deleteProfileImage = async(userId, fileName) => {
    let response = "";
   await usersService.deleteAvatar(userId, fileName).then((res)=>{
     response = res;
    });
    return response;
  }

  return [handleUpdateAvatar, deleteProfileImage, { success, error, loading: !success && !error }];
};
