import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Divider, notification, Popconfirm, Table, Tag } from "antd";
import React from "react";
import { useHistory, useNavigate } from "react-router-dom";
import { useUsers } from "../../../hooks/UserHooks";

const { Column } = Table;

/**
 * The user management table
 */
const UserManagementTable = ({ data, loading }) => {
  const { deleteUser } = useUsers();

  const navigate = useNavigate();

  // Go to the edit user page
  const onEditIconClick = (id) => {
    navigate(`/edit-user/${id}`);
  };

  // Handle delete user
  const onDeleteRecord = (id, username) => {
    deleteUser(id).then(() => {
      notification.success({
        message: `Deleted user successfully!`,
        description: `Deleted ${username} successfully!`,
        placement: "topRight"
      });
    });
  };

  return (
    <>
      <Table dataSource={data} rowKey="email" loading={loading}>
        <Column title="First Name" dataIndex="firstName" />
        <Column title="Last Name" dataIndex="lastName" />
        <Column
          title="Email Address"
          dataIndex="email"
          sorter={(a, b) => a.email.localeCompare(b.email)}
        />
        <Column
          title="Applications"
          dataIndex="userRoles"
          render={(userRoles) => (
            <>
              {userRoles &&
                userRoles.map((userRole) => (
                  <Tag key={userRole} color="green">
                    {userRole}
                  </Tag>
                ))}
            </>
          )}
        />
        <Column
          title="Action"
          width="10%"
          render={({ id, username }) => {
            return (
              <>
                <EditOutlined style={{ fontSize: "20px" }} onClick={() => onEditIconClick(id)} />
                <Divider type="vertical" style={{ display: "hidden" }} />
                <Popconfirm
                  placement="bottomLeft"
                  title="Do you really want to delete this user?"
                  onConfirm={() => {
                    onDeleteRecord(id, username);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <DeleteOutlined style={{ fontSize: "20px" }} />
                  </a>
                </Popconfirm>
              </>
            );
          }}
        />
      </Table>
    </>
  );
};

export default UserManagementTable;
