import { observer } from "mobx-react-lite";
import React from "react";
import { Navigate } from "react-router-dom";
import KeycloakUserService from "../services/KeycloakUserService";

/**
 * Observable-based routing that checks if the user has logged in and has the permission to access a certain page
 */
const AuthRoute = observer(({ Component, requiredAuthentication = false }) => {
  if (requiredAuthentication) {
    if (KeycloakUserService.isLoggedIn()) {
      if (KeycloakUserService.hasXAdminRole()) {
        return <Component />;
      } else {
        KeycloakUserService.doLogout();
        return <Navigate to="/" replace />;
      }
    } else {
      KeycloakUserService.doLogin();
      return null; // Return null to prevent rendering anything
    }
  }
});

export default AuthRoute;
