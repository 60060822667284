import { message } from "antd";
import { ClientError } from "graphql-request";
import { Navigate, Routes, BrowserRouter, Route } from "react-router-dom";
import { SWRConfig } from "swr";
import "./App.css";
import DashboardPage from "./components/pages/DashboardPage/DashboardPage";
import EditUserPage from "./components/pages/EditUserPage/EditUserPage";
import ProfilePage from "./components/pages/ProfilePage/ProfilePage";
import UserManagementPage from "./components/pages/UserManagementPage/UserManagementPage";
import AuthRoute from "./navigation/AuthRole";
import KeycloakUserService from "./services/KeycloakUserService";

const App = () => {
  return (
    <SWRConfig
      value={{
        provider: () => new Map(),
        onError: (error) => {
          if (error instanceof ClientError) {
            if (error.response.errors[0].message === "Unauthorized") {
              message.error("Token is expired!").then();
              KeycloakUserService.doLogout();
            }
          }
        }
      }}
    >
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route
              path="/usage-reports"
              element={<AuthRoute Component={DashboardPage} requiredAuthentication />}
            />
            <Route
              path="/profile"
              element={<AuthRoute Component={ProfilePage} requiredAuthentication />}
            />
            <Route
              path="/user-management"
              element={<AuthRoute Component={UserManagementPage} requiredAuthentication />}
            />
            <Route
              path="/edit-user/:id"
              element={<AuthRoute Component={EditUserPage} requiredAuthentication />}
            />
            <Route path="*" element={<Navigate to="/usage-reports" />} />
          </Routes>
        </BrowserRouter>
      </div>
    </SWRConfig>
  );
};

export default App;
